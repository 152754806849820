import React, { SFC } from 'react';
import { useTranslation } from 'react-i18next';
import './AccessPagesLayout.css';
import { OPTTY_WHITE_SUB_TM_LOGO_URL } from '../../constants/index';
import { TypoGraphy } from '../FormElements';

const AccessPagesLayout: SFC = (props: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <>
      <main className="main-content horizontally-centered vertically-centered">
        <div className="pane-wrapper">
          <section className="left-pane image-decorated">
            <aside className="adjusted-for-contrast positioned-midway">
              <TypoGraphy
                className="text-centered"
                level={2}
              >
                <img
                  alt="Optty logo"
                  height="120px"
                  src={OPTTY_WHITE_SUB_TM_LOGO_URL}
                  width="auto"
                />
              </TypoGraphy>
              <p className="blocktext text-centered">
                {t('accessPageLeftPaneText')} {t('accessPageLeftPaneTextBelow')}
              </p>
              <p className="blocktext text-centered">
                {t('accessPageLeftPaneMottoText')}
              </p>
            </aside>
          </section>
          <section className="right-pane">
            {/* <div className="backBar"> */}
            {/* <CgChevronLeft */}
            {/* onClick={() => history.goBack()} */}
            {/* size="2.5em" */}
            {/* /> */}
            {/* </div> */}
            <div className="content">
              <article className="form-box">{props.children}</article>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AccessPagesLayout;
