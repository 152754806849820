import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { OPTTY_WHITE_LOGO_URL } from '../../../constants';
import './NotFoundError.css';

const NotFoundError = () => {
  return (
    <div className="error-404-wrapper">
      <div className="error-404-container">
        <div className="error-404-inner">
          <div className="error-404-content">
            <div className="brand-area">
              <img
                alt="Optty-Logo"
                className="brand-logo"
                src={OPTTY_WHITE_LOGO_URL}
              />
            </div>
            <div className="text-area">
              <h1>
                404
                <span className="error-404-description">Page not found</span>
              </h1>
              <div className="error-404-divider-container">
                <div className="error-404-divider" />
              </div>
              <br />
              <Link
                className="return-from-error-page"
                to="/app"
              >
                <h3>
                  <ArrowBackIosIcon />
                  Back to dashboard
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
